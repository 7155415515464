import React from 'react';
import { StickyProvider } from 'contexts/app/app.provider'
import SEO from 'components/seo'
import Layout from 'components/layout'

// Load other package css file
import 'react-multi-carousel/lib/styles.css'
import 'react-modal-video/css/modal-video.min.css'
import 'rc-drawer/assets/index.css'
// Custom css style
import 'assets/css/style.css'
// Plugins css style
import 'assets/plugins/bootstrap/bootstrap.min.css'
import 'assets/plugins/themify-icons/themify-icons.css'
import 'assets/plugins/slick/slick.css'
import 'assets/plugins/slick/slick-theme.css'
import 'assets/plugins/fancybox/jquery.fancybox.min.css'
import 'assets/plugins/aos/aos.css'

export default function TermsAndConditionsPage() {
    return (
        <StickyProvider>
            <Layout>
                <SEO title="Termes et conditions" />
                <section class="section page-title">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-8 m-auto">
                                <h1>Termes et conditions</h1>
                                <p>(<em>Dernière mise à jour le 30 mai 2022 à 23h45'</em>)</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="privacy section pt-0">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3">
                                <nav class="privacy-nav">
                                    <ul>
                                        <li><a class="nav-link scrollTo" href="#ourServices">I. Nos services</a></li>
                                        <li><a class="nav-link scrollTo" href="#yourEngagement">II. Vos engagements</a></li>
                                        <li><a class="nav-link scrollTo" href="#otherDispositions">III. Dispositions supplémentaires</a></li>
                                    </ul>
                                </nav>
                            </div>
                            <div class="col-lg-9">
                                <div class="block">
                                    <div id="ourServices" class="policy-item">
                                        <div class="title">
                                            <h3>I. Nos services</h3>
                                        </div>
                                        <div class="policy-details">
                                            <p>Bienvenue sur la plateforme numérique NiWapi accessible via son application mobile
                                                “NiWapi” et son site web “ni-wapi.com”.</p>
                                            <p>Les services de cette plateforme vous sont offerts sous réserve d’acceptation des
                                                termes et conditions énoncés ci-dessous. En accédant ou en utilisant ces services,
                                                vous acceptez d’être lié par le présent Accord et déclarez que vous avez lu et compris
                                                ses conditions. Veuillez donc lire attentivement cet Accord.
                                            </p>
                                            <p>
                                                Nous pouvons changer ou modifier autrement cet Accord à l’avenir en vous le signalant
                                             	à l’avance, et vous comprenez et acceptez que votre
                                                accès ou utilisation continue de nos services après un tel changement signifie votre
                                                acceptation de l’Accord mis à jour ou modifié.
                                            </p>
                                            <p>
                                                La mission de la plateforme NiWapi est de :
                                                <ul>
                                                    <li>
                                                        Fournir des renseignements à sa communauté à propos des établissements locaux 								(bureaux de services professionnels, sièges d’entreprise, bâtiments étatiques, 								les lieux de restauration et de loisir, etc.) et des événements publiques locaux 								(conférences, concerts, etc.) ;
                                                    </li>
                                                    <li>
                                                        Faire la promotion des biens et services des utilisateurs commerçants;
                                                    </li>
                                                    <li>
                                                        Faciliter la communication entre utilisateurs au travers d’annonces publiques et
                                                        privées (pour utilisateurs constitués en groupe restreint).
                                                    </li>
                                                </ul>
                                            </p>
                                            <h4>I.1. Préalables pour l’accès à nos services</h4>
                                            <p>
                                                Afin d’accéder à la plateforme NiWapi et recourir à ses services, vous devrez créer un
                                                compte. Lorsque vous créez un compte, vous devrez fournir des informations
                                                complètes et exactes sur vous.
                                            </p>
                                            <p>
                                                Si vous créez un compte sur notre plateforme à des fins commerciales et que vous
                                                acceptez le présent Contrat au nom d’une entreprise, d’une organisation ou d’une
                                                autre entité juridique, vous déclarez et garantissez que vous êtes autorisé à le faire et
                                                que vous avez le pouvoir de lier cette entité au présent Contrat. Auquel cas, les mots
                                                «vous» et «votre» tels qu’utilisés dans le présent Contrat se rapporteront à cette
                                                entité et à la personne, dénommée «Représentant commercial», agissant au nom de la société.
                                            </p>
                                            <h4>I.2. Accès à la plateforme</h4>
                                            <p>
                                                Pour accéder à notre plateforme, il vous faudra s’authentifier :
                                                <ul>
                                                    <li>
                                                        soit par un compte Gmail,
                                                    </li>
                                                    <li>
                                                        soit par un compte Facebook,
                                                    </li>
                                                    <li>
                                                        soit par un numéro de téléphone opérationnel.
                                                    </li>
                                                </ul>
                                            </p>
                                            <p>
                                                Ensuite, les données suivantes peuvent vous être demandées : le nom, la photo de
                                                profil, le numéro de téléphone, l’adresse mail, le compte Facebook, la tranche d’âge,
                                                le genre, le pays, la province et la ville où vous êtes situé.
                                            </p>
                                            <h4>I.3. Paiement des services</h4>
                                            <p>
                                                Après avoir ouvert un compte sur la plateforme NiWapi, automatiquement un
                                                portefeuille électronique est disponible pour le paiement des publications sur la plateforme. 							Ce portefeuille est alimenté en nombre de crédits provenant de la conversion d’un montant 							d’argent, qui est de la monnaie transférée (en dollar américain ou en franc 							congolais) par mobile money.
                                            </p>
                                            <p>
                                                Pour alimenter votre portefeuille électronique en crédits, vous recourez aux mobiles
                                                money (Airtel money, Orange money et M-pesa). Les numéros téléphoniques sur
                                                lesquels vous transférez votre argent seront renseignés dans votre portefeuille
                                                électronique.
                                            </p>
                                            <p>
                                                Quand vous procédez à un transfert d’argent par mobile money, veuillez saisir le
                                                code reçu du message de confirmation du tranfert sous le champ “Code de vérification”
                                                dans le portefeuille.
                                            </p>
                                            <p>
                                                Vous serez ainsi crédité uniquement si le transfert a bel et bien eu lieu à notre
                                                numéro et que le code renseigné sous le champ “Code de vérification” correspond au
                                                code de transfert reçu par message à notre numéro.
                                            </p>
                                            <p>
                                                En période de promotion, un utilisateur peut recevoir gratuitement de 1 à 10 crédits sur 							son portefeuille quand il sollicite des crédits avec comme code de vérification promotionnel : 							+243. Avant tout, ce demandeur doit renseigner correctement et entièrement les informations 							le concernant sur son profil utilisateur afin de permettre à l’administrateur de bien 							l’identifier avant de valider ces crédits.
                                            </p>
                                            <p>
                                                Pour pouvoir publier, il faut nécessairement avoir dans son portefeuille un minimum de 1 crédit.
						Et lors du processus de publication d’un établissement, d’un évenemrnt, promotion ou annonce, 							l’annonceur veillera à bien renseigner toutes les informations exigées afin que sa publication 							soit validée par l’adminisstrateur de la plateforme.
                                            </p>
                                            <h4>I.4. Facturation des services</h4>
                                            <p>
                                                En ce moment, la création ou la modification d’une publication (établissement, évenement, 							promotion ou annonce) vaut : 1 crédit.
                                            </p> 					
                                            <p>
						Sous la plateforme, la durée de validité ou d’exposition de :
						<ul>
                                                    <li>
                                                        L’établissement est indéterminé ;
                                                    </li>
                                                    <li>
                                                        L’évènement est jusqu’à la dernière date prévue de sa tenue ;
                                                    </li>
                                                    <li>
                                                        La promotion est d’un mois, renouvelable ;
                                                    </li>
                                                    <li>
                                                        L’annonce est d’un mois, renouvelable ;
                                                    </li>
                                                </ul>
                                            </p>
                                            <p>
						Une modification de ces frais et/ou de ces durées pourrait arriver au besoin. 
                                            </p>
                                            <p>
                                                A la souscription à un service de publication, le cout et la durée de validité de la 							publication demeurent inchangeables jusqu’à la fin de cette période de souscription fixée 							au départ. Ce n’est qu’aux prochaines souscriptions que peuvent être appliquées des
                                                modifications éventuelles sur les frais ou le délai de validité.
                                            </p>
                                            <h4>I.5. Procédure de publication sur la plateforme NiWapi</h4>
                                            <p>
                                                <ul>
                                                    <li>
                                                        Après avoir renseigné toutes les informations d’authentification nécessaires et
                                                        alimentées son portefeuille suffisamment, l’utilisateur détaille sa publication en
                                                        remplissant un formulaire et la soumet pour la rendre visible sur la plateforme. 								La publication soumise est mise en attente pour approbation ou désapprobation 								par l’administrateur de la plateforme.
                                                    </li>
                                                    <li>
                                                        Une analyse de la publication est effectuée par notre équipe pour voir si le
                                                        contenu est authentique, approprié et certifié par un auteur reconnu.
                                                    </li>
                                                    <li>
                                                        Après un délai de 48h, la publication est soit validée et votre compte
                                                        déboursé du crédit fixé soit refusée pour des raisons de contenu inapproprié ou
                                                        de crédit insuffisant ou autre.
                                                    </li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                    <div id="yourEngagement" class="policy-item">
                                        <div class="title">
                                            <h3>II. Vos engagements</h3>
                                        </div>
                                        <div class="policy-details">
                                            <p>
                                                Ce service communautaire que nous entamons avec NiWapi sera une réussite que si
                                                vous acceptez de collaborer et respecter votre part de responsabilité. Ainsi, nous
                                                souhaitons que vous preniez les engagements suivants :
                                            </p>
                                            <h4>II.1. À qui est destiné nos services sur NiWapi ?</h4>
                                            <p>
                                                À vous qui allez :
                                                <ul>
                                                    <li>
                                                        Utiliser le même nom que vous utilisez dans la vie de tous les jours.
                                                    </li>
                                                    <li>
                                                        Fournir des informations exactes sur vous-même.
                                                    </li>
                                                    <li>
                                                        Créer un seul compte (le vôtre).
                                                    </li>
                                                    <li>
                                                        Tenir à jour vos coordonnées et les données de votre (vos) publication(s).
                                                    </li>
                                                    <li>
                                                        Ne pas donner accès à votre compte NiWapi à d’autres personnes ou ne
                                                        transférez pas votre compte à quelqu’un d’autre (sans notre autorisation).
                                                    </li>
                                                </ul>
                                            </p>
                                            <p>
                                                Nous essayons de rendre NiWapi largement accessible à tous, mais vous ne pouvez
                                                pas utiliser NiWapi si :
                                                <ul>
                                                    <li>
                                                        Vous avez moins de <strong>14 ans</strong> (ou l’âge minimum légal dans votre pays pour 							utiliser nos services).
                                                    </li>
                                                    <li>
                                                        Vous êtes un délinquant reconnu coupable et vous voulez utiliser notre
                                                        plateforme des fins criminels.
                                                    </li>
                                                    <li>
                                                        Nous avons précédemment désactivé votre compte pour violation de nos
                                                        conditions ou politiques.
                                                    </li>
                                                    <li>
                                                        Il vous est interdit de recevoir nos produits, services ou logiciels en vertu des lois
                                                        applicables.
                                                    </li>
                                                </ul>
                                            </p>
                                            <h4>II.2. Ce que vous pouvez partager et faire sur NiWapi</h4>
                                            <p>
                                                Nous voulons que les gens utilisent NiWapi pour promouvoir leurs activités
                                                professionnelles, ecclésiastiques, communautaires, scolaires ou académiques, et
                                                atteindre plus d’audience avec facilité via cette plateforme numérique.
                                            </p>
                                            <p>
                                                Partager un contenu important pour le public, pour une audience ciblée, pour une
                                                clientèle particulière, mais pas au détriment de la sécurité et du bien-être d’autrui ou
                                                de l’intégrité de notre communauté. Vous vous engagez donc à ne pas vous
                                                méconduire comme décrit ci-dessous (ou à faciliter ou soutenir autrui à le faire):
                                            </p>
                                            <ol>
                                                <li>
                                                    Vous ne pouvez pas utiliser nos services pour faire ou partager quoi que ce soit :
                                                    <ul>
                                                        <li>
                                                            Qui enfreint ces Conditions, nos Normes de la communauté et d’autres conditions
                                                            et politiques qui s’appliquent à votre utilisation de NiWapi.
                                                        </li>
                                                        <li>
                                                            D’illégal, trompeur, discriminatoire ou frauduleux.
                                                        </li>
                                                        <li>
                                                            Qui enfreint ou viole les droits d’autrui, y compris leurs droits de propriété
                                                            intellectuelle.
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    Vous ne pouvez pas télécharger de virus ou de code malveillant ou faire quoi que
                                                    ce soit qui pourrait désactiver, surcharger ou altérer le bon fonctionnement ou
                                                    l’apparence de nos services.
                                                </li>
                                                <li>
                                                    Vous ne pouvez pas accéder ou collecter des données de nos services en utilisant
                                                    des moyens automatisés (sans notre autorisation préalable) ou tenter d’accéder à
                                                    des données auxquelles vous n’êtes pas autorisé à accéder.
                                                </li>
                                            </ol>
                                            <h4>II.3. Les autorisations que vous nous accordez</h4>
                                            <p>
                                                Nous avons besoin de certaines autorisations de votre part pour fournir nos
                                                services :
                                                <ol>
                                                    <li>
                                                        Autorisation d’utiliser le contenu que vous créez et partagez : certains contenus
                                                        que vous partagez ou téléversez, tels que des photos ou des textes, peuvent être
                                                        protégés par les lois sur la propriété intellectuelle.
                                                        <p>
                                                            Vous possédez les droits de propriété intellectuelle (comme les droits d’auteur ou 								    les marques de commerce) sur tout contenu que vous créez et partagez sur NiWapi. 								    Rien dans ces Conditions ne vous enlève les droits dont vous disposez sur votre 								    propre contenu. Vous êtes libre de partager votre contenu avec n’importe qui, où 								    que vous soyez.
                                                            <br />
                                                            Cependant, pour fournir nos services, nous avons besoin que vous nous accordiez
                                                            certaines autorisations légales (appelées «licence») pour utiliser ce contenu. Ceci 								est uniquement dans le but de fournir et d’améliorer nos produits et services.
                                                            <br />
                                                            Plus précisément, lorsque vous partagez ou publiez du contenu couvert par des
                                                            droits de propriété intellectuelle ou en relation avec nos produits, vous nous
                                                            accordez une licence d’hébergeur non exclusive, transférable, sous-licenciable, 									libre de droits et mondiale, utiliser, distribuer, modifier, exécuter, copier, 									exécuter ou
                                                            afficher publiquement, traduire et créer des œuvres dérivées de votre contenu
                                                            (conformément à vos paramètres de confidentialité et d’application). Cela signifie,
                                                            par exemple, que si vous partagez une photo sur NiWapi, vous nous autorisez à la
                                                            stocker, la copier et la partager avec d’autres (encore une fois, conformément à vos
                                                            paramètres) tels que les fournisseurs de services qui prennent en charge notre
                                                            service (Google, par exemple) ou d’autres produits NiWapi. Cette licence prendra fin
                                                            lorsque votre contenu sera supprimé de nos systèmes.
                                                            <br />
                                                            Lorsque vous supprimez du contenu, il n’est plus visible pour les autres 									utilisateurs, mais il peut continuer à exister ailleurs sur nos systèmes où la 									suppression immédiate n’est pas possible en raison de limitations techniques 									(dans ce cas, votre contenu sera supprimé un peu plutard mais dans un délai 									raisonnable).
                                                        </p>
                                                    </li>
                                                    <li>
                                                        Autorisation de rendre vos publications plus attrayantes, visibles auprès des 								utilisateurs de NiWapi : vos publications peuvent notifiées à un grand nombre 								d’utilisateurs à l’échelle nationale, provinciale ou urbaine.                         
                                                    </li>
                                                    <li>
                                                        Autorisation de mettre à jour le logiciel que vous utilisez : si vous
                                                        utilisez notre logiciel, vous nous autorisez à téléverser les mises à jour du logiciel, 							le cas échéant.
                                                    </li>
                                                </ol>
                                            </p>
                                            <h4>II.4. Limites d’utilisation de notre propriété intellectuelle</h4>
                                            <p>
                                                Si vous utilisez du contenu couvert par les droits de propriété intellectuelle que nous
                                                détenons et que nous mettons à disposition dans nos produits (par exemple, des
                                                images, des dessins, des textes que nous fournissons sur NiWapi), nous conservons tous les 							droits sur ce contenu. Vous ne pouvez utiliser nos droits d’auteur ou marques de commerce
                                                (ou toute marque similaire) que dans la mesure expressément autorisée par nos
                                                directives d’utilisation de la marque ou avec notre autorisation écrite préalable. Vous
                                                devez obtenir notre autorisation écrite (ou autorisation sous une licence open source)
                                                pour modifier, créer des œuvres dérivées de, décompiler ou tenter d’extraire du code
                                                source de notre part.
                                            </p>
                                        </div>
                                    </div>
                                    <div id="otherDispositions" class="policy-item">
                                        <div class="title">
                                            <h3>III. Dispositions supplémentaires</h3>
                                        </div>
                                        <div class="policy-details">
                                            <h4>III.1. Mise à jour de nos conditions</h4>
                                            <p>
                                                Nous travaillons constamment pour améliorer nos services et développer de
                                                nouvelles fonctionnalités pour améliorer nos produits pour vous et notre
                                                communauté. En conséquence, nous pouvons avoir besoin de mettre à jour ces
                                                conditions de temps à autre pour refléter avec précision nos services et nos
                                                pratiques. Nous n’apporterons des modifications que si les dispositions ne sont plus
                                                appropriées ou si elles sont incomplètes, et uniquement si les modifications sont
                                                raisonnables et tiennent dûment compte de vos intérêts.
                                            </p>
                                            <p>
                                                Nous vous informerons (par exemple, par e-mail ou via nos produits) au moins quelques
                                                jours avant d’apporter des modifications aux présentes Conditions et vous donnerons
                                                la possibilité de les consulter avant qu’elles n’entrent en vigueur, à moins que des
                                                modifications ne soient requises par la loi. Une fois que les conditions mises à jour
                                                seront en vigueur, vous serez lié par celles-ci si vous continuez à utiliser nos produits.
                                            </p>
                                            <p>
                                                Nous espérons que vous continuerez à utiliser nos produits, mais si vous n’acceptez
                                                pas nos conditions mises à jour et ne souhaitez plus faire partie de la communauté
                                                NiWapi, vous pouvez supprimer votre compte à tout moment.
                                            </p>
                                            <h4>III.2. Suspension ou résiliation du compte</h4>
                                            <p>
                                                NiWapi se doit d’être une plateforme où les gens se sentent les bienvenus et en
                                                sécurité pour consulter et partager leurs informations relatives à leurs activités
                                                locales comme mentionnées précédemment.
                                            </p>
                                            <p>
                                                Si nous déterminons que vous avez clairement, sérieusement ou à plusieurs reprises
                                                enfreint nos Conditions ou Politiques, nous pouvons suspendre ou désactiver
                                                définitivement l’accès à votre compte. Nous pouvons également suspendre ou
                                                désactiver votre compte si vous enfreignez à plusieurs reprises les droits de propriété
                                                intellectuelle d’autrui ou lorsque nous sommes tenus de le faire pour des raisons
                                                légales.
                                            </p>
                                            <p>
                                                Lorsque nous prenons de telles mesures, nous vous informerons et vous
                                                expliquerons toutes les options dont vous disposez pour demander un examen, sauf
                                                si cela peut nous exposer ou exposer d’autres personnes à une responsabilité légale;
                                                nuire à notre communauté d’utilisateurs; compromettre ou interférer avec l’intégrité
                                                ou le fonctionnement de l’un de nos services, systèmes ou produits; ou lorsque nous
                                                sommes limités en raison de limitations techniques; ou lorsque cela nous est interdit
                                                pour des raisons juridiques.
                                            </p>
                                            <p>
                                                Si vous pensez que nous avons désactivé ou supprimé votre compte par erreur ou si
                                                nous avons rejeté ou supprimé votre publication sans raison, veuillez-nous le faire
                                                savoir en nous contactant.
                                            </p>
                                            <p>
                                                Si vous supprimez ou désactivez votre compte, ces Conditions prendront fin en tant
                                                qu’accord entre vous et nous, mais les dispositions suivantes resteront en vigueur : II.4.
                                            </p>
                                            <h4>III.3. Limites de responsabilité</h4>
                                            <p>
                                                Nous travaillons dur pour fournir les meilleurs produits possibles et pour spécifier
                                                des directives claires pour tous ceux qui les utilisent. Nos produits, cependant, sont
                                                fournis «tels quels» et nous ne garantissons pas qu’ils seront toujours sûrs, sécurisés
                                                ou exempts d’erreurs, ou qu’ils fonctionneront sans interruption, retard ou
                                                imperfection. Dans la mesure permise par la loi, nous DÉCLINONS également TOUTE
                                                GARANTIE, EXPRESSE OU IMPLICITE D’ADÉQUATION À UN USAGE PARTICULIER (DE
                                                TITRE ET D’ABSENCE DE CONTREFAÇON). Nous ne contrôlons ni ne dirigeons ce que
                                                les gens font ou disent, et nous ne sommes pas responsables de leurs publications
                                                (sous la plateforme NiWapi) ou de tout contenu qu’ils partagent. Toutefois nous
                                                ferons de notre mieux de rejeter absolument tout contenu offensant, inapproprié,
                                                obscène, illégal et autre contenu répréhensible. Tout contenu est soumis à notre
                                                équipe pour son analyse avant sa publication sur la plateforme.
                                            </p>
                                            <p>
                                                Pour nous aider dans notre mission, nous vous encourageons à signaler le contenu qui, selon 							vous, viole vos droits ou les droits d’autrui (y compris les droits de propriété 							intellectuelle) ou nos conditions et politiques.
                                            </p>
                                            <p>
                                                En utilisant nos services, vous pouvez rencontrer des liens vers des sites et des applications 							tiers ou être en mesure d’interagir avec des sites et des applications tiers. Ces liens sont 							renseignés au travers des publications créées par des utilisateurs de la plateforme. Par-là, 							vous devez comprendre que nous ne garantissons pas la fiabilité de ces sites ou applications 							tiers. Si vous décidez de suivre leurs liens, cela n’engagera que vous avec ces tiers.
                                            </p>
                                            <p>
                                                Nous ne pouvons pas prédire quand des problèmes pourraient survenir avec nos
                                                produits. En conséquence, notre responsabilité sera limitée dans toute la mesure
                                                permise par la loi applicable, et en aucun cas nous ne serons responsables envers
                                                vous des pertes de profits, revenus, informations ou données, ou les dommages
                                                accessoires découlant de ou liés à ces Conditions ou aux Produits NiWapi, même si
                                                nous avons été informés de la possibilité de tels dommages.
                                            </p>
                                            <h4>III.4. Règlement de litige</h4>
                                            <p>
						Les Parties se conviennent une résolution à l’amiable en cas de tout désaccord lors de 							l’exécution ou interprétation des termes et conditions. Si aucune solution à l’amiable n’est 							trouvée, les parties feront recours à la juridiction du ressort compétant.                                                
                                            </p>
                                            <h4>III.5. Autres</h4>
                                            <p>
                                                <ul>
                                                    <li>
                                                        Ces Conditions constituent l’intégralité de l’accord entre vous et NiWapi concernant
                                                        votre utilisation de nos produits. Ils remplacent tout accord antérieur.
                                                    </li>
                                                    <li>
                                                        Certains des produits que nous proposons sont également régis par des conditions
                                                        supplémentaires. Si vous utilisez l’un de ces produits, des conditions
                                                        supplémentaires seront mises à disposition et feront partie de notre accord avec
                                                        vous. Dans la mesure où des conditions supplémentaires entrent en conflit avec
                                                        ces conditions, les conditions supplémentaires prévaudront dans la mesure du
                                                        conflit.
                                                    </li>
                                                    <li>
                                                        Si une partie de ces Conditions s’avère inapplicable, la partie restante restera
                                                        pleinement en vigueur. Si nous ne parvenons pas à appliquer l’une de ces
                                                        conditions, cela ne sera pas considéré comme une renonciation. Toute
                                                        modification ou renonciation à ces conditions doit être faite par écrit et signée par
                                                        nous.
                                                    </li>
                                                    <li>
                                                        Vous ne transférerez aucun de vos droits ou obligations en vertu des présentes
                                                        Conditions à quiconque sans notre consentement.
                                                    </li>
                                                    <li>
                                                        Vous pouvez désigner une personne pour gérer votre compte s’il est légalement
                                                        connu comme héritier. Seule la personne que vous avez identifiée dans un
                                                        testament valide ou un document similaire exprimant un consentement clair à
                                                        divulguer votre contenu en cas de décès ou d’incapacité sera en mesure de
                                                        demander la divulgation de votre compte après sa reconnaissance légale.
                                                    </li>
                                                    <li>
                                                        Ces Conditions ne confèrent aucun droit de bénéficiaire tiers. Tous nos droits et
                                                        obligations en vertu des présentes Conditions sont librement cessibles par nous
                                                        dans le cadre d’une fusion, d’une acquisition ou d’une vente d’actifs, ou par l’effet
                                                        de la loi ou autrement.
                                                    </li>
                                                    <li>
                                                        Vous devez savoir que nous pouvons avoir besoin de changer le nom d’utilisateur
                                                        de votre compte dans certaines circonstances (par exemple, si quelqu’un d’autre
                                                        réclame le nom d’utilisateur et qu’il semble sans rapport avec le nom que vous
                                                        utilisez dans la vie de tous les jours). Nous vous informerons à l’avance si nous
                                                        devons le faire et vous expliquerons pourquoi.
                                                    </li>
                                                    <li>
                                                        Nous apprécions toujours vos commentaires et autres suggestions sur nos
                                                        produits et services. Mais sachez que nous pouvons les utiliser sans aucune
                                                        restriction ni obligation de vous indemniser, et nous n’avons aucune obligation de
                                                        les garder confidentiels.
                                                    </li>
                                                    <li>
                                                        Nous nous réservons tous les droits qui ne vous sont pas expressément accordés.
                                                    </li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </StickyProvider>
    );
}
